import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  close(event) {
    event.preventDefault()
    this.modalTarget.classList.add("hidden")
    this.modalTarget.classList.remove("modal")
  }

  open(event) {
    event.preventDefault()
    this.modalTarget.classList.add("modal")
    this.modalTarget.classList.remove("hidden")
  }
}
